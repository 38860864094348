import React, {useState, useEffect, useContext} from 'react';
import {ListGroup} from 'react-bootstrap';
import {Link, useLocation} from 'react-router-dom';
//import navigation from "../../../menu-items";
import {BASE_TITLE, BASENAME} from '../../../config/constant';
import {decode as base64_decode} from 'base-64';
import {ConfigContext} from '../../../contexts/ConfigContext';

const Breadcrumb = () => {
  const configContext = useContext(ConfigContext);
  const {menuUsuario} = configContext.state;
  const [navigation, setNavigation] = useState([]);
  useEffect(() => {
    let navigation = [];
    try {
      const usersRt = JSON.parse(base64_decode(sessionStorage.getItem('ddesp')));
      const users = usersRt;
      const menu = users[10];
      navigation = menu[0];

      if (menuUsuario.length === 0) {
        navigation = menu[0];
      } else {
        navigation = menuUsuario; //menu[0];
      }

      setNavigation(navigation);
    } catch (exc) {}
  }, []);

  const [main, setMain] = useState([]);
  const [item, setItem] = useState([]);
  const location = useLocation();

  useEffect(() => {
    if (navigation.items !== undefined && Object.keys(navigation.items).length > 0) {
      navigation.items.map((item, index) => {
        if (item.type && item.type === 'group') {
          getCollapse(item, index);
        }
        return false;
      });
    }
  });

  const getCollapse = (item) => {
    if (item.children) {
      item.children.filter((collapse) => {
        if (collapse.type && collapse.type === 'collapse') {
          getCollapse(collapse);
        } else if (collapse.type && collapse.type === 'item') {
          if (document.location.pathname === BASENAME + collapse.url) {
            setMain(item);
            setItem(collapse);
          }
        }
        return false;
      });
    }
  };

  let mainContent, itemContent;
  let breadcrumbContent = '';
  let title = '';

  if (main && main.type === 'group') {
    mainContent = (
      <ListGroup.Item
        as='li'
        bsPrefix=' '
        className='breadcrumb-item'
      >
        <Link to='#'>{main.title}</Link>
      </ListGroup.Item>
    );
  }

  if (item && item.type === 'item') {
    title = item.title;

    itemContent = (
      <ListGroup.Item
        as='li'
        bsPrefix=' '
        className='breadcrumb-item'
      >
        <Link to='#'>{title}</Link>
      </ListGroup.Item>
    );

    if (location.pathname.indexOf('faturas') > - 1) {
      breadcrumbContent = '';
    }
    //if (item.breadcrumbs !== false && item.id !== '3' && item.id !== '5' && item.id !== '24' && item.id !== 'hnfse' && item.id !== 'hsat') {
    if (location.pathname.indexOf('faturas') == -1 && item.breadcrumbs !== false && item.url.indexOf('history') === -1 && item.url.indexOf('editarPerfil') === -1 && item.url.indexOf('relatorios') === -1 && item.url.indexOf('dashboard') === -1 && item.url.indexOf('aplicativos') === -1 && location.pathname.indexOf('planos') === -1 && location.pathname.indexOf('resgate') === -1) {
      breadcrumbContent = (
        <div className='page-header'>
          <div className='page-block'>
            <div className='row align-items-center'>
              <div className='col-md-12'>
                <div className='page-header-title'>
                  <h5 className='m-b-10'>{title}</h5>
                </div>
                <ListGroup
                  as='ul'
                  bsPrefix=' '
                  className='breadcrumb'
                >
                  <ListGroup.Item
                    as='li'
                    bsPrefix=' '
                    className='breadcrumb-item'
                  >
                    <Link to='/'>
                      <i className='feather icon-home' />
                    </Link>
                  </ListGroup.Item>
                  {mainContent}
                  {itemContent}
                </ListGroup>
              </div>
            </div>
          </div>
        </div>
      );
    }

    document.title = title + BASE_TITLE;
  }

  return <React.Fragment>{breadcrumbContent}</React.Fragment>;
};

export default Breadcrumb;
