import { Suspense, Fragment, lazy } from 'react';
import {Switch, Redirect, Route} from 'react-router-dom';

import Loader from './components/Loader/Loader';
import AdminLayout from './layouts/AdminLayout';
import middleLayout from './layouts/middleLayout';

import GuestGuard from './components/Auth/GuestGuard';
import AuthGuard from './components/Auth/AuthGuard';

function ValidaRoutes() {
  //
  // Rota quando primeiro acesso para responder questionario
  // if (primeiroAcesso === true) {

  return [
    {
      exact: true,
      guard: GuestGuard,
      path: '/auth/login',
      component: lazy(() => import('./views/auth/login/login')),
    },
    {
      exact: true,
      guard: GuestGuard,
      path: '/auth/acesso-varias-empresas',
      component: lazy(() => import('./views/auth/acessoVarias')),
    },
    {
      exact: true,
      guard: GuestGuard,
      path: '/auth/cadastro',
      component: lazy(() => import('./views/auth/cadastrar/cadastro')),
    },
    {
      exact: true,
      guard: GuestGuard,
      path: '/auth/resetar-senha',
      component: lazy(() => import('./views/auth/reset-password/solicitarRedefinicao')),
    },
    {
      exact: true,
      guard: GuestGuard,
      path: '/auth/redefinir',
      component: lazy(() => import('./views/auth/reset-password/ResetPassword1')),
    },
    {
      exact: true,
      guard: GuestGuard,
      path: '/auth/cadastro-usuario',
      component: lazy(() => import('./views/auth/cadastro-usuario/')),
    },
    {
      path: '/inicio/*',
      layout: AdminLayout,
      guard: AuthGuard,
      routes: [
        {
          exact: true,
          path: '/inicio/boasVindas',
          component: lazy(() => import('./views/primeiroAcesso/PrimeiroAcesso')),
        },
        {
          exact: true,
          path: '/inicio/tipoTeste',
          component: lazy(() => import('./views/primeiroAcesso/TipoTeste')),
        },
        {
          exact: true,
          path: '/inicio/primeiraEmpresa',
          component: lazy(() => import('./views/primeiroAcesso/PrimeiraEmpresa')),
        },
      ],
    },
    {
      layout: AdminLayout,
      guard: AuthGuard,
      routes: [
        {
          exact: true,
          path: '/liberarAcesso',
          component: lazy(() => import('./views/auth/bloqueio/')),
        },
        {
          exact: true,
          path: '/dashboard',
          component: lazy(() => import('./views/dashboard/')),
        },

        {
          exact: true,
          path: '/planos',
          component: lazy(() => import('./views/planos')),
        },
        {
          exact: true,
          path: '/upgrade',
          component: lazy(() => import('./views/outros/upgrade')),
        },
        {
          exact: true,
          path: '/primeiraConsulta',
          component: lazy(() => import('./views/primeiroAcesso/PrimeiraConsulta')),
        },
        {
          exact: true,
          path: '/history/nfe',
          component: lazy(() => import('./views/history/nfe')),
        },
        {
          exact: true,
          path: '/usuarios',
          component: lazy(() => import('./views/usuarios/gerenciar')),
        },
        {
          exact: true,
          path: '/empresas',
          component: lazy(() => import('./views/empresas/')),
        },
        {
          exact: true,
          path: '/certificados',
          component: lazy(() => import('./views/certificados')),
        },
        // {
        //   exact: true,
        //   path: '/empresas/cadastro',
        //   component: lazy(() => import('./views/empresas/cadastrar')),
        // },
        {
          exact: true,
          path: '/grupos',
          component: lazy(() => import('./views/grupos')),
        },
        {
          exact: true,
          path: '/relatorios/nfe',
          component: lazy(() => import('./views/relatorios/nfe')),
        },
        {
          exact: true,
          path: '/relatorios/cte',
          component: lazy(() => import('./views/relatorios/cte')),
        },
        {
          exact: true,
          path: '/relatorios/nfce',
          component: lazy(() => import('./views/genericos/outros/emBreve')),
        },
        {
          exact: true,
          path: '/history/nfce',
          component: lazy(() => import('./views/history/nfce')),
        },
        {
          exact: true,
          path: '/history/cte',
          component: lazy(() => import('./views/history/cte')),
        },
        {
          exact: true,
          path: '/history/nfse',
          component: lazy(() => import('./views/history/nfse')),
        },
        {
          exact: true,
          path: '/history/sat',
          component: lazy(() => import('./views/history/sat')),
        },
        {
          exact: true,
          path: '/outros/sped',
          component: lazy(() => import('./views/outros/sped')),
        },
        {
          exact: true,
          path: '/outros/aplicativos',
          component: lazy(() => import('./views/outros/aplicativos')),
        },
        {
          exact: true,
          path: '/notificacoes',
          component: lazy(() => import('./views/notificacoes')),
        },
        // {
        //   exact: true,
        //   path: '/outros/aplicativos/checkout',
        //   component: lazy(() => import('./views/outros/aplicativos/checkout')),
        // },
        {
          exact: true,
          path: '/outros/monitor',
          component: lazy(() => import('./views/outros/monitor')),
        },
        {
          exact: true,
          path: '/outros/downloads',
          component: lazy(() => import('./views/outros/downloads')),
        },
        {
          exact: true,
          path: '/outros/resgateXML',
          component: lazy(() => import('./views/outros/resgatexml')),
        },
        {
          exact: true,
          path: '/usuarios/editarPerfil',
          component: lazy(() => import('./views/usuarios/editarPerfil')),
        },
        {
          exact: true,
          path: '/faturas',
          component: lazy(() => import('./views/faturas')),
        },
        {
          exact: true,
          path: '/atualizacoes',
          component: lazy(() => import('./views/atualizacoes')),
        },
        {
          exact: true,
          path: '/meuPlano',
          component: lazy(() => import('./views/meuPlano')),
        },

        {
          exact: true,
          path: '/403',
          component: lazy(() => import('./views/auth/403')),
        },
        // {
        //   exact: true,
        //   path: '/maintance',
        //   component: lazy(() => import('./views/outros/emBreve')),
        // },
        {
          exact: true,
          guard: GuestGuard,
          path: '/auth/acesso-varias-empresas',
          component: lazy(() => import('./views/auth/acessoVarias')),
        },
        {
          path: '*',
          exact: true,
          component: () => <Redirect to={'/dashboard'} />,
        },
      ],
    },
  ];
}

export function renderRoutes(routes = []) {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;
          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}

const routes = ValidaRoutes();

export default routes;
