
import $ from 'jquery';
import {ENDERECO_API} from '../../config/constant';
import { decode as base64_decode } from 'base-64';


const RestHistoryNfsePost = (config) => {
  
    const Arr_ = JSON.parse(config.data);
    const caminho = Arr_.dest.trim();
    if (caminho === 'dataIni') {
      return new Promise(function (resolve, reject) {
        try {
          const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
          const storage = JSON.parse(usersRt);
          const emp = storage[0];
          const uid = storage[3];
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/historyNfse_controller.class.php`,
            type: 'Get',
            dataType: 'json',
            data: {
              act: 'dataIni',
              key: emp,
              ukey: uid,
            },
            success: async function (data, status, xhr) {
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    } else if (caminho === 'pesqNfse') {
      return new Promise(function (resolve, reject) {
        const {dataPost} = JSON.parse(config.data);
        try {
          const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
          const storage = JSON.parse(usersRt);
          const emp = storage[0];
          const uid = storage[3];
          /* props: users,
                    pesq: dataPost*/
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/historyNfse_controller.class.php`,
            type: 'Get',
            dataType: 'json',
            data: {
              act: 'pesqNfse',
              key: emp,
              ukey: uid,
              opt: dataPost,
            },
            success: async function (data, status, xhr) {
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err + ' Server Error'}]);
        }
      });
    } else if (caminho === 'pesqNfseNa') {
      return new Promise(function (resolve, reject) {
        const {dataPost} = JSON.parse(config.data);
        try {
          const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
          const storage = JSON.parse(usersRt);
          const emp = storage[0];
          const uid = storage[3];
          /* props: users,
                    pesq: dataPost*/
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/historyNfse_controller.class.php`,
            type: 'Get',
            dataType: 'json',
            data: {
              act: 'pesqNfseNa',
              key: emp,
              ukey: uid,
              opt: dataPost,
            },
            success: async function (data, status, xhr) {
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err + ' Server Error'}]);
        }
      });
    } else if (caminho === 'salvaConfigTabela') {
      return new Promise(function (resolve, reject) {
        const {dataPost} = JSON.parse(config.data);
        try {
          const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
          const storage = JSON.parse(usersRt);
          const emp = storage[0];
          const uid = storage[3];
          /* props: users,
                    pesq: dataPost*/
          
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/historyNfse_controller.class.php`,
            type: 'Post',
            dataType: 'json',
            data: {
              act: 'updateConfigTabela',
              key: emp,
              ukey: uid,
              opt: dataPost,
            },
            success: async function (data, _status, _xhr) {
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err + ' Server Error'}]);
        }
      });
    } else if (caminho === 'salvaConfigTabelaNa') {
      return new Promise(function (resolve, reject) {
        const {dataPost} = JSON.parse(config.data);
        try {
          const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
          const storage = JSON.parse(usersRt);
          const emp = storage[0];
          const uid = storage[3];
          /* props: users,
                    pesq: dataPost*/
          
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/historyNfse_controller.class.php`,
            type: 'Post',
            dataType: 'json',
            data: {
              act: 'updateConfigTabelaNa',
              key: emp,
              ukey: uid,
              opt: dataPost,
            },
            success: async function (data, _status, _xhr) {
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err + ' Server Error'}]);
        }
      });
    } else if (caminho === 'downXML') {
      return new Promise(function (resolve, reject) {
        const {dataPost} = JSON.parse(config.data);
        
        try {
          const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
          const storage = JSON.parse(usersRt);
          const emp = storage[0];
          const uid = storage[3];
          /* props: users,
                    pesq: dataPost*/
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/historyNfse_controller.class.php`,
            type: 'Post',
            dataType: 'json',
            data: {
              act: 'geraXml',
              key: emp,
              ukey: uid,
              chave: dataPost[0],
              cnpj: dataPost[1],
            },
            success: async function (data, _status, _xhr) {
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err + ' Server Error'}]);
        }
      });
    } else if (caminho === 'printDanfse') {
      return new Promise(function (resolve, reject) {
        const {dataPost} = JSON.parse(config.data);

        try {
          const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
          const storage = JSON.parse(usersRt);
          const emp = storage[0];
          const uid = storage[3];
          /* props: users,
                    pesq: dataPost*/
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/historyNfse_controller.class.php`,
            type: 'Post',
            dataType: 'json',
            data: {
              act: 'printDanfse',
              key: emp,
              ukey: uid,
              chave: dataPost[0],
              cnpj: dataPost[1],
            },
            success: async function (data, _status, _xhr) {
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err + ' Server Error'}]);
        }
      });
    } else if (caminho === 'salvaNovoMarcador') {
      return new Promise(function (resolve, reject) {
        const {dataPost} = JSON.parse(config.data);
        try {
          const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
          const storage = JSON.parse(usersRt);
          const emp = storage[0];
          const uid = storage[3];
          /* props: users,
                    pesq: dataPost*/
          
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/historyNfse_controller.class.php`,
            type: 'Post',
            dataType: 'json',
            data: {
              act: 'salvaNovoMarcadorNfe',
              key: emp,
              ukey: uid,
              opt: dataPost,
              tipo: 'nfse',
            },
            success: async function (data, _status, _xhr) {
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err + ' Server Error'}]);
        }
      });
    } else if (caminho === 'deleteMarcador') {
      return new Promise(function (resolve, reject) {
        const {dataPost} = JSON.parse(config.data);
        try {
          const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
          const storage = JSON.parse(usersRt);
          const emp = storage[0];
          const uid = storage[3];
          /* props: users,
                    pesq: dataPost*/
          
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/historyNfse_controller.class.php`,
            type: 'Post',
            dataType: 'json',
            data: {
              act: 'deleteMarcadores',
              key: emp,
              ukey: uid,
              opt: dataPost,
            },
            success: async function (data, _status, _xhr) {
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err + ' Server Error'}]);
        }
      });
    } else if (caminho === 'hideMarcador') {
      return new Promise(function (resolve, reject) {
        const {dataPost} = JSON.parse(config.data);
        try {
          const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
          const storage = JSON.parse(usersRt);
          const emp = storage[0];
          const uid = storage[3];
          /* props: users,
                    pesq: dataPost*/
          
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/historyNfse_controller.class.php`,
            type: 'Post',
            dataType: 'json',
            data: {
              act: 'hideMarcadores',
              key: emp,
              ukey: uid,
              opt: dataPost,
            },
            success: async function (data, _status, _xhr) {
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err + ' Server Error'}]);
        }
      });
    } else if (caminho === 'showMarcador') {
      return new Promise(function (resolve, reject) {
        const {dataPost} = JSON.parse(config.data);
        try {
          const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
          const storage = JSON.parse(usersRt);
          const emp = storage[0];
          const uid = storage[3];
          /* props: users,
                    pesq: dataPost*/
          
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/historyNfse_controller.class.php`,
            type: 'Post',
            dataType: 'json',
            data: {
              act: 'showMarcadores',
              key: emp,
              ukey: uid,
              opt: dataPost,
            },
            success: async function (data, _status, _xhr) {
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err + ' Server Error'}]);
        }
      });
    } else if (caminho === 'setMarcador') {
      return new Promise(function (resolve, reject) {
        const {dataPost} = JSON.parse(config.data);
        try {
          const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
          const storage = JSON.parse(usersRt);
          const emp = storage[0];
          const uid = storage[3];
          /* props: users,
                    pesq: dataPost*/

          $.ajax({
            url: `${ENDERECO_API}src/api/controller/historyNfse_controller.class.php`,
            type: 'Post',
            dataType: 'json',
            data: {
              act: 'setMarcador',
              key: emp,
              ukey: uid,
              opt: dataPost,
            },
            success: async function (data, _status, _xhr) {
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err + ' Server Error'}]);
        }
      });
    } else if (caminho === 'downloadLoteTodas') {
      return new Promise(function (resolve, reject) {
        const {dataPost} = JSON.parse(config.data);
        try {
          const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
          const storage = JSON.parse(usersRt);
          const emp = storage[0];
          const uid = storage[3];
          /* props: users,
                    pesq: dataPost*/
          
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/historyNfse_controller.class.php`,
            type: 'Post',
            dataType: 'json',
            data: {
              act: 'downloadLoteTodas',
              key: emp,
              ukey: uid,
              opt: dataPost,
            },
            success: async function (data, _status, _xhr) {
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err + ' Server Error'}]);
        }
      });
    }else if (caminho === 'downloadLote') {
      return new Promise(function (resolve, reject) {
        const {dataPost} = JSON.parse(config.data);
        try {
          const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
          const storage = JSON.parse(usersRt);
          const emp = storage[0];
          const uid = storage[3];
          /* props: users,
                    pesq: dataPost*/
          
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/historyNfse_controller.class.php`,
            type: 'Post',
            dataType: 'json',
            data: {
              act: 'downloadLote',
              key: emp,
              ukey: uid,
              opt: dataPost,
            },
            success: async function (data, _status, _xhr) {
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err + ' Server Error'}]);
        }
      });
    } else if ( caminho === 'enviaArquivosEmail') {
      return new Promise(function (resolve, reject) {
        const {dataPost} = JSON.parse(config.data);
        try {
          const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
          const storage = JSON.parse(usersRt);
          const emp = storage[0];
          const uid = storage[3];
          /* props: users,
                    pesq: dataPost*/
          
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/historyNfse_controller.class.php`,
            type: 'Post',
            dataType: 'json',
            data: {
              act: 'enviaArquivosEmail',
              key: emp,
              ukey: uid,
              opt: dataPost,
            },
            success: async function (data, _status, _xhr) {
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              console.log(xhr);
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err + ' Server Error'}]);
        }
      });
    } else if (caminho === 'enviaArquivosEmailTodos') {
      return new Promise(function (resolve, reject) {
        const {dataPost} = JSON.parse(config.data);
        
        try {
          const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
          const storage = JSON.parse(usersRt);
          const emp = storage[0];
          const uid = storage[3];
          /* props: users,
                    pesq: dataPost*/
          
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/historyNfse_controller.class.php`,
            type: 'Post',
            dataType: 'json',
            data: {
              act: 'enviaArquivosEmailTodos',
              key: emp,
              ukey: uid,
              opt: dataPost,
            },
            success: async function (data, _status, _xhr) {
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              console.log(xhr);
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err + ' Server Error'}]);
        }
      });
    }
    
    else if (caminho === 'salvaDataAuto') {
      return new Promise(function (resolve, reject) {
        const {dataPost} = JSON.parse(config.data);
        try {
          const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
          const storage = JSON.parse(usersRt);
          const emp = storage[0];
          const uid = storage[3];
          
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/historyNfse_controller.class.php`,
            type: 'Post',
            dataType: 'json',
            data: {
              act: 'salvaDataAuto',
              key: emp,
              ukey: uid,
              opt: dataPost,
            },
            success: async function (data, _status, _xhr) {
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err}]);
        }
      });
    }
}
;
const RestHistoryNfseGet = (config) => { 
   
    const caminho = config.dest.trim();
    const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
    const storage = JSON.parse(usersRt);
    const emp = storage[0];
    const uid = storage[3];
    if (caminho === 'getConfigTableNfse') {
      return new Promise(function (resolve, reject) {
        try {
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/historyNfse_controller.class.php`,
            type: 'Get',
            dataType: 'json',
            data: {
              act: 'getConfigTableNfse',
              key: emp,
              ukey: uid,
            },
            success: async function (data, status, xhr) {
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          
          reject([500, {message: err}]);
        }
      });
    } else if (caminho === 'getConfigTableNfseNa') {
      return new Promise(function (resolve, reject) {
        try {
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/historyNfse_controller.class.php`,
            type: 'Get',
            dataType: 'json',
            data: {
              act: 'getConfigTableNfseNa',
              key: emp,
              ukey: uid,
            },
            success: async function (data, status, xhr) {
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          
          reject([500, {message: err}]);
        }
      });
    } else if (caminho === 'getMarcadores') {
      return new Promise(function (resolve, reject) {
        try {
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/historyNfse_controller.class.php`,
            type: 'Get',
            dataType: 'json',
            data: {
              act: 'getMarcadores',
              key: emp,
              ukey: uid,
              tipo: 'nfse',
            },
            success: async function (data, status, xhr) {
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          
          reject([500, {message: err}]);
        }
      });
    } else if (caminho === 'getVinculadosNfse') {
      return new Promise(function (resolve, reject) {
        try {
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/historyNfse_controller.class.php`,
            type: 'Get',
            dataType: 'json',
            data: {
              act: 'getVinculadosNfe',
              key: emp,
              ukey: uid,
              opt: config.dataPost,
            },
            success: async function (data, status, xhr) {
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          
          reject([500, {message: err}]);
        }
      });
    } else if (caminho === 'getDataAuto') {

     const {dataPost} = config;
  
      return new Promise(function (resolve, reject) {
        
        try {
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/historyNfse_controller.class.php`,
            type: 'Get',
            dataType: 'json',
            data: {
              act: 'getDataAuto',
              key: emp,
              ukey: uid,
              dataPost: dataPost,
            },
            success: async function (data, status, xhr) {
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              console.log(xhr);
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          
          reject([500, {message: err}]);
        }
      });
    }
}

export {RestHistoryNfsePost, RestHistoryNfseGet};
