import React, {useContext, useEffect, useRef, useState} from 'react';
import Navigation from './Navigation';
import NavBar from './NavBar';

import Countdown, {zeroPad, calcTimeDelta, formatTimeDelta} from 'react-countdown';
import axios from '../../services/axios';
import Breadcrumb from './Breadcrumb';
import useWindowSize from '../../hooks/useWindowSize';
import useOutsideClick from '../../hooks/useOutsideClick';
import {ConfigContext} from '../../contexts/ConfigContext';
import * as actionType from '../../store/actions';
import {Alert, Button, Col, Row, Card, Modal, Form} from 'react-bootstrap';
import {Link, useLocation, useHistory} from 'react-router-dom';
import {decode as base64_decode} from 'base-64';

// import faixaBf from '../../assets/images/faixaBf.png';

import recesso from '../../assets/images/pop up.png';

import moment from 'moment';
import {set} from 'date-fns';

const AdminLayout = ({children}) => {
  const history = useHistory();
  const windowSize = useWindowSize();
  const ref = useRef();
  const configContext = useContext(ConfigContext);
  const {collapseMenu, layout, subLayout, headerFixedLayout} = configContext.state;
  const {dispatch} = configContext;
  const storage_ = sessionStorage.getItem('ddesp');
  const [plano, setPlano] = useState('');
  const [dataContratacao, setDataContratacao] = useState('');
  const [demonstracao, setDemonstracao] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [diasRestantes, setDiasRestantes] = useState(0);
  const [bloqConta, setBloqConta] = useState(false);
  const location = useLocation();
  const [expirou, setExpirou] = useState(false);

  const [notificacaoBancoDados, setNotificacaoBancoDados] = useState([]);

  // const [mdRecesso, setMdRecesso] = useState(localStorage.getItem('recesso') === 'true' ? false : true);

  const FraseDemonstracao = () => {
    return (
      <span>
        {diasRestantes !== 1 ? (
          <>
            ⌛ Faltam apenas <strong>{diasRestantes} dias</strong> para testar o sistema.
          </>
        ) : (
          <>
            ⌛ Falta apenas <strong>{diasRestantes} dia</strong> para testar o sistema.
          </>
        )}
      </span>
    );
  };

  const FraseAlerta = () => {
    return (
      <span>
        <span>
          Falta apenas <strong> dia</strong> para testar o sistema.
        </span>
      </span>
    );
  };

  useEffect(() => {
    const alertClosed = sessionStorage.getItem('alertDClosed');
    if (alertClosed) {
      setIsVisible(false);
    }
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    sessionStorage.setItem('alertDClosed', 'true');
  };

  useEffect(() => {
    let usersRt = null;
    if (storage_ !== null) {
      usersRt = JSON.parse(base64_decode(storage_));
      if (usersRt) {
        const plano = usersRt[17];
        const dataContratacao = moment(usersRt[27]).format('DD/MM/YYYY');
        const dataContratacaoMoment = moment(dataContratacao, 'DD/MM/YYYY');

        //
        const dataAtual = moment();
        const diferenca = dataAtual.diff(dataContratacaoMoment, 'days');
        setExpirou(usersRt[29]);

        setBloqConta(usersRt[28]);
        setPlano(plano);
        setDataContratacao(dataContratacao);
        setDiasRestantes(7 - diferenca);
        setDemonstracao(plano.toLowerCase().indexOf('demo') > -1 && diferenca < 7);
      }
    }
  }, [storage_]);

  useOutsideClick(ref, () => {
    if (collapseMenu) {
      dispatch({type: actionType.COLLAPSE_MENU});
    }
  });

  useEffect(() => {
    if (windowSize.width > 992 && windowSize.width <= 1024 && layout !== 'horizontal') {
      dispatch({type: actionType.COLLAPSE_MENU});
    }

    if (layout === 'horizontal' && windowSize.width < 992) {
      dispatch({type: actionType.CHANGE_LAYOUT, layout: 'vertical'});
    }
    
  }, [dispatch, layout, windowSize]);


  useEffect(() => {
    //axios.post('/api/initData/', {dest: 'dataIni'});
    axios.get('api/initData/', {dest: 'notificacoes'}).then((response) => {
      if (response.data.length > 0) {
        let notify_ = '';

        notify_ = (
          <Row className='pcoded-content'>
            <Col sm={12}>
              <Alert
                variant={response.data[0].fundo}
                className='text-center p-1'
              >
                <span className={`${response.data[0].icone} mr-2 float-left`} />
                <span className={`${response.data[0].cor_fonte} f-16`}>{response.data[0].texto}</span>
                {response.data[0].link !== null && response.data.link !== 'null' && (
                  <span>
                    <a
                      className='badge badge-success f-w-400 ml-3 pointer'
                      target='_blank'
                      href={response.data[0].link}
                    >
                      Saiba mais
                    </a>
                  </span>
                )}
                <span
                  className='float-right pointer mr-2 f-w-600'
                  onClick={() => {
                    setNotificacaoBancoDados([]);
                  }}
                >
                  X
                </span>
              </Alert>
            </Col>
          </Row>
        );
        setNotificacaoBancoDados(notify_);
      }
    });
  }, []);


  const mobileOutClickHandler = () => {
    if (windowSize.width < 992 && collapseMenu) {
      dispatch({type: actionType.COLLAPSE_MENU});
    }
  };

  let mainClass = ['pcoded-wrapper'];
  if (layout === 'horizontal' && subLayout === 'horizontal-2') {
    mainClass = [...mainClass, 'container'];
  }
  let common =
    location.pathname.indexOf('inicio') === -1 ? (
      <>
        <Navigation />
        <NavBar />
      </>
    ) : (
      <>
        <Navigation />
        <NavBar />
      </>
    );

  if (windowSize.width < 992) {
    let outSideClass = ['nav-outside'];
    if (collapseMenu) {
      outSideClass = [...outSideClass, 'mob-backdrop'];
    }
    if (headerFixedLayout) {
      outSideClass = [...outSideClass, 'mob-fixed'];
    }

    common = (
      <div
        className={outSideClass.join(' ')}
        ref={ref}
      >
        {common}
      </div>
    );
  }

  return (
    <>
      {common}
      <div
        className='pcoded-main-container mt-3 pl-3'
        onClick={mobileOutClickHandler}
      >
        <div className={mainClass.join(' ')}>
          {/* {isVisible && bloqConta !== 't' && (
            <Row className='pcoded-content'>
              <Col sm={12}>
                <Alert
                  variant='success' 
                  className='text-center p-1'
                >
                  <span className='mdi mdi-bell mr-2 float-left' />
                  🎄💌 Uma carta aberta com recado importante para você! <a href='https://materiais.espiaonfe.com.br/festas-2024'>Clique aqui</a>
                </Alert>
              </Col>
            </Row>
          )} */}

          {demonstracao && isVisible && bloqConta !== 't' && (
            <Row className='pcoded-content'>
              <Col sm={12}>
                <Alert
                  variant='success'
                  className='text-center p-1'
                >
                  <span className='mdi mdi-bell mr-2' />
                  {FraseDemonstracao()}{' '}
                  <a
                    href='https://api.whatsapp.com/send/?phone=5516993043258&text&type=phone_number&app_absent=0'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Conversar com um especialista Espião
                    <span className='mdi mdi-whatsapp ml-1'></span>
                  </a>
                  <Link to='/planos'>
                    <Button
                      size='sm'
                      variant='success'
                      className='ml-2'
                      onClick={() => history.push('/planos')}
                    >
                      Assine já
                    </Button>
                  </Link>
                  <Button
                    onClick={handleClose}
                    variant='none'
                    className='text-success'
                    style={{position: 'absolute', right: '10px'}}
                  >
                    <span className='mdi mdi-close' />
                  </Button>
                </Alert>
              </Col>
            </Row>
          )}

          {!demonstracao && isVisible && bloqConta !== 't' && notificacaoBancoDados != '' && notificacaoBancoDados}

          {/* <Modal
            size='lg'
            show={mdRecesso}
            onHide={() => {
              setMdRecesso(false);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>🏖️ Recesso!</Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-0'>
              <img
                src={recesso}
                alt='Recesso'
                className='img-fluid'
              />
            </Modal.Body>
            <div
              className='p-1'
              style={{padding: '0 10px'}}
            >
              <div className='text-center'>
                <p className='text-center f-w-600'>Retornaremos às atividades normais dia 02/01/2025 a partir das 13h (Horário de Brasília)</p>
                <Form.Check
                  type='checkbox'
                  label='Marque aqui para não exibir este aviso novamente.'
                  onChange={(e) => {
                    setMdRecesso(false);
                    localStorage.setItem('recesso', e.target.checked);
                  }}
                  className='ml-3 mb-2'
                />{' '}
              </div>
            </div>
          </Modal> */}

          <div className='pcoded-content'>
            <div className='pcoded-inner-content'>
              <Breadcrumb />
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLayout;
